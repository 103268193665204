/* eslint-disable react/no-children-prop */
import styles from "./AppMessages.module.css";
import MessagesIndex from "./MessagesIndex";
import Conversation from "./Conversation";
import ArchivedPasswordInput from "./ArchivedPasswordInput";

import { useAppStore, MediaViewer } from "@faintlines/phone-sim-common";
import "@faintlines/phone-sim-common/dist/index.css";

import React, { useState } from "react";
import { Route } from "react-router-dom";
import urljoin from "url-join";
import classnames from "classnames";
import { observer } from "mobx-react-lite";

function AppMessages({
    appName,
    appTitle,
    appUrl,
    appPath,
    conversations,
    contacts, // Todo: Remove
    archived,
    onPasswordAttempt,
    onEvent,
}) {
    const [image, setImage] = useState(null);
    const store = useAppStore();
    const { dynamicMessages, keypadVisible } = store;
    conversations = conversations || contacts; // backwards compatibility
    const allConversations = [...(dynamicMessages || []), ...conversations];
    const archivedConversations = archived?.conversations || archived?.contacts;
    const hasArchived =
        archived &&
        (archived.contacts?.length > 0 || archived.conversations?.length > 0);
    const imageClickHandler = (src, text) => {
        setImage({ src, text });
    };

    return (
        <div className={styles.app}>
            <Route
                exact
                path={urljoin(appPath, "conversation-:conversationId")}
                children={({ match }) => (
                    <div
                        className={classnames(
                            styles.content,
                            match?.params?.conversationId ? styles.active : null
                        )}
                    >
                        <MessagesIndex
                            conversations={allConversations}
                            archivedConversations={archivedConversations}
                            appTitle={appTitle}
                            appUrl={appUrl}
                        />
                        <Conversation
                            appUrl={appUrl}
                            conversationId={match?.params?.conversationId}
                            conversations={[
                                ...(allConversations || []),
                                ...(archivedConversations || []),
                            ]}
                            onImageClick={imageClickHandler}
                            onEvent={onEvent}
                        />
                    </div>
                )}
            />
            {image ? (
                <MediaViewer image={image.src} onClose={() => setImage(null)} />
            ) : null}
            {hasArchived && archived.password ? (
                <ArchivedPasswordInput
                    password={archived.password}
                    hint={archived.passwordHint}
                    visible={keypadVisible}
                    onUnlock={() => store.showArchivedMessages()}
                    onCancel={() => store.toggleKeypad(false)}
                    onPasswordAttempt={onPasswordAttempt}
                    appName={appName}
                />
            ) : null}
        </div>
    );
}

export default observer(AppMessages);
