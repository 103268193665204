import styles from "./AppDownloader.module.css";
import IndexPage from "./IndexPage";
import SearchPage from "./SearchPage";
import AppPage from "./AppPage";

import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import urljoin from "url-join";

const AppDownloader = ({ apps, categories, appTitle, appUrl, appPath }) => (
    <div className={styles.app}>
        <Switch>
            <Route exact path={appPath}>
                <IndexPage
                    categories={categories || []}
                    apps={apps || {}}
                    appTitle={appTitle}
                    appUrl={appUrl}
                />
            </Route>
            <Route exact path={urljoin(appPath, "search")}>
                <SearchPage
                    apps={apps || {}}
                    appTitle={appTitle}
                    appUrl={appUrl}
                />
            </Route>
            <Route
                exact
                path={urljoin(appPath, "app", ":appId")}
                render={({ match }) => (
                    <AppPage
                        appId={match.params.appId}
                        app={(apps || {})[match.params.appId]}
                    />
                )}
            />
            <Route>
                <Redirect to={appUrl} />
            </Route>
        </Switch>
    </div>
);

export default AppDownloader;
