import styles from "./IndexPage.module.css";
import SEARCH_PLACEHOLDER from "../utils/searchPlacholder";

import { Image } from "@faintlines/image";

import React from "react";
import { Link } from "react-router-dom";
import urljoin from "url-join";
import { Search as IconSearch } from "lucide-react";

const IndexPage = ({ categories, apps, appTitle, appUrl }) => (
    <div className={styles.index__scroller}>
        <div className={styles.index}>
            <div className={styles.index__header}>
                <div className={styles.index__title}>
                    {appTitle || "App Downloader"}
                </div>
            </div>
            <Link
                to={urljoin(appUrl, "search")}
                className={styles.index__searchButton}
            >
                <IconSearch
                    className={styles.index__searchButton__icon}
                    size="1.1em"
                />
                {SEARCH_PLACEHOLDER}
            </Link>
            <div className={styles.index__categories}>
                {categories?.length
                    ? categories.map((cat, catIndex) => (
                          <IndexCategory
                              key={catIndex}
                              category={cat}
                              apps={apps}
                              appUrl={appUrl}
                          />
                      ))
                    : null}
            </div>
        </div>
    </div>
);

export default IndexPage;

const IndexCategory = ({ category, apps, appUrl }) => (
    <div className={styles.category}>
        <div className={styles.category__title}>{category.title}</div>
        <div className={styles.category__apps}>
            {category.apps.map((appId) => (
                <IndexCategoryApp
                    key={appId}
                    app={apps[appId]}
                    appId={appId}
                    appUrl={appUrl}
                />
            ))}
        </div>
    </div>
);

const IndexCategoryApp = ({ app, appId, appUrl }) => (
    <Link className={styles.categoryApp} to={urljoin(appUrl, "app", appId)}>
        <div className={styles.categoryApp__icon}>
            <Image
                className={styles.categoryApp__icon__image}
                src={app.icon}
                showSpinner
                alt={app.name}
            />
        </div>
        <div className={styles.categoryApp__title}>{app.name}</div>
        {app.developer ? (
            <div className={styles.categoryApp__developer}>{app.developer}</div>
        ) : null}
    </Link>
);
