import styles from "./AppSettings.module.css";

import { useAppStore, PasswordLocker } from "@faintlines/phone-sim-common";
import "@faintlines/phone-sim-common/dist/index.css";

import React from "react";
import "react-toggle/style.css";
import { observer } from "mobx-react-lite";
import classnames from "classnames";
import Toggle from "react-toggle";

export default function AppSettings({ settings, appTitle, onEvent }) {
    return (
        <div className={styles.app}>
            <div className={styles.app__title}>{appTitle || "Settings"}</div>
            <Settings settings={settings} onEvent={onEvent} />
            <Keypad onEvent={onEvent} />
        </div>
    );
}

const Settings = ({ settings, onEvent }) => (
    <div className={styles.settings}>
        {settings.map((setting) => (
            <Setting key={setting.id} {...setting} onEvent={onEvent} />
        ))}
    </div>
);

const Setting = observer(({ type, ...props }) => {
    if (type === "button") {
        return <ButtonSetting {...props} />;
    }
    if (type === "toggle") {
        return <ToggleSetting {...props} />;
    }
    return null;
});

const ButtonSetting = observer(
    ({ title, subtitle, buttonText, password, clickEvent, onEvent }) => {
        const showKeypad = useKeypad({
            password,
            unlockEvent: clickEvent,
            onEvent,
        });

        return (
            <SettingLayout title={title} subtitle={subtitle} layout="button">
                <div className={styles.setting__button} onClick={showKeypad}>
                    {buttonText}
                </div>
            </SettingLayout>
        );
    }
);

const ToggleSetting = observer(
    ({ id, title, subtitle, password, toggleEvent, onEvent }) => {
        const store = useAppStore();
        const showKeypad = useKeypad({
            password,
            unlockEvent: toggleEvent,
            toggleId: id,
            onEvent,
        });

        return (
            <SettingLayout title={title} subtitle={subtitle} layout="toggle">
                <Toggle
                    className={styles.setting__toggle}
                    checked={store.getToggleValue(id)}
                    icons={false}
                    onChange={showKeypad}
                />
            </SettingLayout>
        );
    }
);

const SettingLayout = ({ title, subtitle, layout, children }) => (
    <div className={classnames(styles.setting, styles[layout])}>
        <div className={styles.setting__info}>
            <div className={styles.setting__title}>{title}</div>
            {subtitle ? (
                <div className={styles.setting__subtitle}>{subtitle}</div>
            ) : null}
        </div>
        <div className={styles.setting__children}>{children}</div>
    </div>
);

const Keypad = observer(({ onEvent }) => {
    const store = useAppStore();
    const { keypad } = store;

    if (!keypad) {
        return null;
    }

    const { unlockEvent, toggleId } = keypad;

    const handleUnlock = () => {
        if (unlockEvent && onEvent) {
            onEvent(unlockEvent);
        }
        if (toggleId) {
            store.toggleValue(toggleId);
        }
        store.hideKeypad();
    };
    const handleCancel = () => store.hideKeypad();

    return (
        <div className={styles.password}>
            <PasswordLocker
                title={keypad.title}
                hint={keypad.hint}
                password={keypad.password}
                allowTypos
                unlockText={keypad.unlockText}
                onUnlock={handleUnlock}
                onCancel={handleCancel}
            />
        </div>
    );
});

function useKeypad({ password, unlockEvent, toggleId, onEvent }) {
    const store = useAppStore();

    const handleClick = () => {
        if (password) {
            store.showKeypad({ ...password, unlockEvent, toggleId });
        } else {
            if (onEvent && unlockEvent) {
                onEvent(unlockEvent);
            }
            if (toggleId) {
                store.toggleValue(toggleId);
            }
        }
    };

    return handleClick;
}
