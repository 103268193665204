import styles from "./PasswordLocker.module.css";
import PasswordInput from "./PasswordInput";
import { vibrateShort } from "../utils/vibrate";

import { stringsInclude } from "@faintlines/string-utils";

import React, { useState } from "react";
import classnames from "classnames";

const PasswordLocker = ({
    className,
    appName,
    title,
    hint,
    unlockText,
    color,
    password,
    allowTypos,
    onUnlock,
    onCancel,
    onAttempt,
}) => {
    const [value, setValue] = useState("");
    const [animated, setAnimated] = useState(false);

    function onSubmit() {
        if (stringsInclude(password, value, { allowTypos })) {
            onUnlock();
            if (onAttempt) {
                onAttempt({ appName, password: value, success: true });
            }
        } else {
            vibrateShort();
            setAnimated(true);
            setTimeout(() => {
                setAnimated(false);
            }, 300);
            if (onAttempt) {
                onAttempt({ appName, password: value, success: false });
            }
        }
    }

    return (
        <div className={classnames(styles.locker, className)}>
            {title ? <div className={styles.title}>{title}</div> : null}
            {hint ? <div className={styles.hint}>{`Hint: ${hint}`}</div> : null}
            <PasswordInput
                className={classnames(styles.textbox, { animated })}
                formClassName={styles.textbox_form}
                color={color}
                value={value}
                onChange={(evt) => setValue(evt.target.value)}
            />
            <div className={styles.submit} onClick={onSubmit}>
                {unlockText || "Unlock"}
            </div>
            {onCancel ? (
                <div className={styles.cancel} onClick={onCancel}>
                    {"Cancel"}
                </div>
            ) : null}
        </div>
    );
};

export default PasswordLocker;
