import "./AppSocial.css"; // TODO: convert to module
import styles from "./AppSocial.module.css";

import { randomNormalInt, seedFromString } from "@faintlines/random-utils";
import { formatIntegerHuman, formatDate } from "@faintlines/string-utils";
import { useMarkdownContent } from "@faintlines/markdown";
import { ContactPhoto } from "@faintlines/phone-sim-common";
import { Image } from "@faintlines/image";

import {
    Heart as IconHeart,
    Repeat as IconRepeat,
    MessageSquare as IconMessageSquare,
    Calendar as IconCalendar,
} from "lucide-react";
import React, { useEffect, useRef } from "react";
import {
    Switch,
    Route,
    Link,
    NavLink,
    useParams,
    useLocation,
    withRouter,
    Redirect,
} from "react-router-dom";
import urljoin from "url-join";
import { observer } from "mobx-react-lite";
import classnames from "classnames";
import { last, isFinite } from "lodash";

const DEFAULT_ENGAGEMENT_RATE = 0.01;

function AppSocial({
    messages,
    notifications,
    feed,
    profiles,
    theme,
    appUrl,
    appPath,
}) {
    const themeClassName = theme ? `theme-${theme}` : null;

    return (
        <div className={classnames(styles.app, "AppSocial", themeClassName)}>
            <Header rootUrl={appUrl} />
            <Switch>
                <Route exact path={appPath}>
                    <NewsFeed feed={feed} profiles={profiles} appUrl={appUrl} />
                </Route>
                <Route exact path={urljoin(appPath, "messages")}>
                    <MessagesIndex messages={messages || []} appUrl={appUrl} />
                </Route>
                <Route exact path={urljoin(appPath, "notifications")}>
                    <Notifications
                        notifications={notifications || []}
                        appUrl={appUrl}
                    />
                </Route>
                <Route exact path={urljoin(appPath, "messages/:threadId")}>
                    <MessagesThread appUrl={appUrl} messages={messages || []} />
                </Route>
                <Route
                    exact
                    path={urljoin(appPath, "profile/:profileId")}
                    render={({ match }) => (
                        <ProfilePage
                            profile={profiles[match.params.profileId]}
                            appUrl={appUrl}
                        />
                    )}
                />
                <Route>
                    <Redirect to={appUrl} />
                </Route>
            </Switch>
        </div>
    );
}

export default observer(AppSocial);

const Header = withRouter(({ rootUrl, location }) => {
    const { pathname } = location;

    const title = pathname.includes("messages")
        ? "Chat"
        : pathname.includes("notifications")
        ? "Notifications"
        : "News Feed";

    const indicatorLeft = pathname.includes("messages")
        ? "200%"
        : pathname.includes("notifications")
        ? "100%"
        : 0;

    return (
        <div className="AppSocial-header">
            <div className="AppSocial-header-title">{title}</div>
            <div className="AppSocial-header-menu">
                <NavLink
                    exact
                    to={rootUrl}
                    className="AppSocial-header-menu-button"
                >
                    <i className="icon-newspaper" />
                </NavLink>
                <NavLink
                    to={urljoin(rootUrl, "notifications")}
                    className="AppSocial-header-menu-button"
                >
                    <i className="icon-globe" />
                </NavLink>
                <NavLink
                    to={urljoin(rootUrl, "messages")}
                    className="AppSocial-header-menu-button"
                >
                    <i className="icon-comment" />
                </NavLink>
            </div>
            <div
                className="AppSocial-header-menu-indicator"
                style={{ transform: `translateX(${indicatorLeft})` }}
            />
        </div>
    );
});

function NewsFeed({ feed, profiles, appUrl }) {
    return (
        <div className="AppSocial-newsfeed">
            {feed ? (
                feed.map((post, i) => (
                    <Post
                        key={i}
                        post={post}
                        profile={profiles[post.profileId]}
                        appUrl={appUrl}
                    />
                ))
            ) : (
                <div className="AppSocial-newsfeed-no-news">
                    <div className="AppSocial-newsfeed-no-news-icon icon-newspaper" />
                    {"No News"}
                </div>
            )}
        </div>
    );
}

function Notifications({ notifications }) {
    const noNotifications = notifications.length === 0;

    return (
        <div className="AppSocial-notifications">
            {noNotifications ? (
                <div className="AppSocial-notifications-none">
                    <div className="AppSocial-notifications-none-icon icon-globe" />
                    {"No Notifications"}
                </div>
            ) : null}
        </div>
    );
}

function MessagesIndex({ messages, appUrl }) {
    return (
        <div className="AppSocial-messages">
            {messages.map((thread, threadId) => (
                <Link
                    className="AppSocial-messages-contact"
                    key={threadId}
                    to={urljoin(appUrl, "messages", threadId.toString())}
                >
                    <div className="AppSocial-messages-contact-icon-wrapper">
                        <ContactPhoto
                            contact={{
                                name: thread.name || thread.contacts[0]?.name,
                                photo:
                                    thread.photo || thread.contacts[0]?.photo,
                            }}
                        />
                    </div>
                    <div className="AppSocial-messages-contact-details">
                        <div className="AppSocial-messages-contact-name">
                            {thread.name || thread.contacts[0]?.name}
                        </div>
                        <div className="AppSocial-messages-contact-last-message">
                            {last(thread.messages)?.text}
                            <span className="AppSocial-messages-contact-last-message-time">
                                {messageTime(last(thread.messages))}
                            </span>
                        </div>
                    </div>
                </Link>
            ))}
        </div>
    );
}

function MessagesThread({ messages }) {
    const { threadId } = useParams();
    const { pathname } = useLocation();
    const scroller = useRef();

    useEffect(() => {
        if (scroller.current) {
            scroller.current.scrollTop = scroller.current.scrollHeight;
        }
    }, [pathname]);

    if (!threadId) {
        return null;
    }

    const thread = messages[threadId];
    if (!thread) {
        return null;
    }

    return (
        <div className="AppSocial-thread">
            <div className="AppSocial-thread-top-bar">
                <div className="AppSocial-thread-details-name">
                    {thread.name || thread.contacts[0]?.name}
                </div>
            </div>
            <div className="AppSocial-thread-messages-wrapper" ref={scroller}>
                <div className="AppSocial-thread-messages">
                    {thread.messages.map((message, i) => (
                        <Message
                            key={i}
                            message={message}
                            contacts={thread.contacts}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

function Message({ message, contacts }) {
    const { fromIndex } = message;
    const contact = contacts[fromIndex];
    const outgoing = fromIndex === -1;
    const MarkdownContent = useMarkdownContent();

    return (
        <div
            className={classnames("AppSocial-message", outgoing ? "out" : "in")}
        >
            {outgoing ? null : (
                <ContactPhoto
                    className="AppSocial-message-from-photo"
                    contact={contact || {}}
                    size={30}
                />
            )}
            <div className="AppSocial-message-content">
                <div
                    className={classnames("AppSocial-message-content-inner", {
                        text: !!message.text,
                        image: !!message.image,
                    })}
                >
                    {message.image ? (
                        <Image
                            className="AppSocial-message-content-image"
                            src={message.image}
                        />
                    ) : null}
                    <MarkdownContent
                        className="AppSocial-message-content-text"
                        markdown={message.text}
                    />
                </div>
                <div className="AppSocial-message-time">
                    {messageTime(message)}
                </div>
            </div>
        </div>
    );
}

function Post({ post, profile, appUrl }) {
    const { name, username, picture } = profile;
    const { time, content, image, youtube, giphy } = post;
    const { likes, reposts, comments } = calcPostEngagement(post, profile);
    const MarkdownContent = useMarkdownContent();

    return (
        <div className={styles.post}>
            <Link to={urljoin(appUrl, "profile", username)}>
                <div className={styles.post__profilePic}>
                    <img
                        className={styles.post__profilePic__image}
                        src={picture}
                        alt={name}
                    />
                </div>
            </Link>
            <div className={styles.post__contents}>
                <div className={styles.post__header}>
                    <span className={styles.post__header__info}>
                        <span className={styles.post__header__name}>
                            {name}
                        </span>
                        <span
                            className={styles.post__header__username}
                        >{`@${username}`}</span>
                    </span>
                    <span className={styles.post__header__time}>{time}</span>
                </div>
                <MarkdownContent
                    className={styles.post__body}
                    markdown={content}
                />
                {image ? (
                    <div className={styles.post__image}>
                        <img
                            className={styles.post__image__image}
                            src={image}
                            alt=""
                        />
                    </div>
                ) : youtube ? (
                    <YoutubeVideo youtubeId={youtube} />
                ) : giphy ? (
                    <Giphy gifId={giphy} />
                ) : null}
                <div className={styles.post__stats}>
                    <div className={styles.post__stats__item}>
                        <IconHeart size="1.1em" />
                        {likes ? formatIntegerHuman(likes) : null}
                    </div>
                    <div className={styles.post__stats__item}>
                        <IconRepeat size="1.1em" />
                        {reposts ? formatIntegerHuman(reposts) : null}
                    </div>
                    <div className={styles.post__stats__item}>
                        <IconMessageSquare size="1.1em" />
                        {comments ? formatIntegerHuman(comments) : null}
                    </div>
                </div>
            </div>
        </div>
    );
}

function ProfilePage({ profile, appUrl }) {
    const exists = !!profile;
    const { name, username, picture, backgroundPicture } = profile || {};
    const scroller = useRef();

    useEffect(() => {
        scroller.current.scrollTop = 0;
    }, [username]);

    return (
        <div className={styles.profile} ref={scroller}>
            <div className={styles.profile__header}>
                <div className={styles.profile__header_backgroundPic}>
                    {backgroundPicture ? (
                        <img
                            src={backgroundPicture}
                            className={
                                styles.profile__header_backgroundPic__image
                            }
                            alt={name}
                        />
                    ) : null}
                </div>
                <div className={styles.profile__header_contents}>
                    <div className={styles.profile__profilePic}>
                        {picture ? (
                            <img
                                src={picture}
                                className={styles.profile__profilePic__image}
                                alt={name}
                            />
                        ) : null}
                    </div>
                    <div className={styles.profile__name}>
                        {exists ? name : `@${username}`}
                    </div>
                    {profile ? (
                        <ProfileHeaderContents profile={profile} />
                    ) : null}
                </div>
            </div>
            <ProfileContents profile={profile || {}} appUrl={appUrl} />
        </div>
    );
}

function ProfileHeaderContents({ profile }) {
    const { username, bio, joined, following, followers } = profile;
    const MarkdownContent = useMarkdownContent();

    return (
        <>
            <div className={styles.profile__username}>{`@${username}`}</div>
            <MarkdownContent className={styles.profile__bio} markdown={bio} />
            {joined ? (
                <div className={styles.profile__joined}>
                    <IconCalendar size="1em" />
                    {`Joined ${joined}`}
                </div>
            ) : null}
            <div className={styles.profile__network}>
                <div className={styles.profile__network__item}>
                    <span className={styles.profile__network__number}>
                        {formatIntegerHuman(following)}
                    </span>
                    {"Following"}
                </div>
                <div className={styles.profile__network__item}>
                    <span className={styles.profile__network__number}>
                        {formatIntegerHuman(followers)}
                    </span>
                    {"Followers"}
                </div>
            </div>
        </>
    );
}

function ProfileContents({ profile, appUrl }) {
    if (!profile) {
        return (
            <div className={styles.profile__doesNotExist}>
                <div className={styles.profile__doesNotExist__title}>
                    {"This account doesn't exist"}
                </div>
                <div className={styles.profile__doesNotExist__subtitle}>
                    {"Try searching for another."}
                </div>
            </div>
        );
    }

    return (
        <div className={styles.profile__posts}>
            <div className={styles.profile__posts__title}>{"Posts"}</div>
            {(profile.posts || []).map((post, i) => (
                <Post key={i} post={post} profile={profile} appUrl={appUrl} />
            ))}
        </div>
    );
}

function YoutubeVideo({ youtubeId }) {
    return (
        <div className={styles.youtube}>
            <iframe
                width="100%"
                height="auto"
                src={`https://www.youtube-nocookie.com/embed/${youtubeId}`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            />
        </div>
    );
}

function Giphy({ gifId }) {
    return (
        <div className={styles.giphy}>
            <div
                style={{
                    width: "100%",
                    height: 0,
                    "padding-bottom": "75%",
                    position: "relative",
                }}
            >
                <iframe
                    src={`https://giphy.com/embed/${gifId}`}
                    width="100%"
                    height="100%"
                    style={{ position: "absolute" }}
                    frameBorder="0"
                    className="giphy-embed"
                    title="Giphy"
                    allowFullScreen
                />
            </div>
        </div>
    );
}

function calcPostEngagement(post, profile) {
    const { username, followers } = profile;
    let { engagement } = profile;
    const { content } = post;
    let { likes, reposts, comments } = post;

    const seed = seedFromString(`${username}${content}`);

    if (!isFinite(engagement)) {
        engagement = DEFAULT_ENGAGEMENT_RATE;
    }

    if (!isFinite(likes)) {
        likes = Math.max(
            0,
            randomNormalInt(
                followers * engagement,
                (followers * engagement) / 2,
                seed
            )
        );
    }
    if (!isFinite(reposts)) {
        reposts = Math.max(0, randomNormalInt(likes / 3, likes / 6, seed + 1));
    }
    if (!isFinite(comments)) {
        comments = Math.max(0, randomNormalInt(likes / 4, likes / 8, seed + 2));
    }

    return { likes, reposts, comments };
}

function messageTime(message) {
    if (!message) {
        return "";
    }
    return message.date
        ? `${formatDate(message.date, "MMM D")}, ${message.time}`
        : message.time;
}
