import styles from "./IndexView.module.css";
import { calcTileDimensions } from "../utils/AppBooksUtils";

import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import urljoin from "url-join";

const IndexView = ({ sections, titles, appUrl }) => (
    <div className={styles.indexView}>
        {sections?.map((section, sectionIdx) => (
            <IndexSection
                key={sectionIdx}
                section={section}
                titles={titles}
                appUrl={appUrl}
            />
        ))}
    </div>
);

export default IndexView;

const IndexSection = ({ section, titles, appUrl }) => {
    const [screenRef, screenWidth] = useScreenWidth();
    const sectionTitles = section.titles.map((x) => titles[x]);

    return (
        <div className={styles.section} ref={screenRef}>
            <div className={styles.section__name}>{section.name}</div>
            <div className={styles.section_titles}>
                {sectionTitles.map((title, titleIdx) => {
                    const titleName = section.titles[titleIdx];
                    return (
                        <Link
                            className={styles.section_title}
                            style={{
                                backgroundImage: `url(${title.image})`,
                                ...calcTileDimensions(screenWidth),
                            }}
                            key={titleIdx}
                            to={urljoin(appUrl, "title", titleName)}
                        />
                    );
                })}
            </div>
        </div>
    );
};

const useScreenWidth = () => {
    const screenRef = useRef();
    const [screenWidth, setScreenWidth] = useState(null);

    useEffect(() => {
        const currentWidth = screenRef.current?.clientWidth;
        if (currentWidth && currentWidth !== screenWidth) {
            setScreenWidth(currentWidth);
        }
    }, [screenRef, screenWidth]);

    return [screenRef, screenWidth];
};
