import styles from "./ArchivedPasswordInput.module.css";

import { PasswordLocker } from "@faintlines/phone-sim-common";
import "@faintlines/phone-sim-common/dist/index.css";

import React from "react";
import classnames from "classnames";

const ArchivedPasswordInput = ({
    appName,
    hint,
    visible,
    password,
    onPasswordAttempt,
    onUnlock,
    onCancel,
}) => (
    <div
        className={classnames(styles.password, visible ? styles.visible : null)}
    >
        <PasswordLocker
            className={styles.passwordLocker}
            appName={`${appName} archived`}
            title="Enter password"
            hint={hint}
            color="white"
            allowTypos
            password={password}
            onAttempt={onPasswordAttempt}
            onUnlock={onUnlock}
            onCancel={onCancel}
        />
    </div>
);

export default ArchivedPasswordInput;
