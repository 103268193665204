import styles from "./BackButton.module.css";

import React from "react";
import { Link } from "react-router-dom";

const BackButton = ({ url }) => (
    <Link className={styles.backButton} to={url}>
        <i className="icon-left-open-big" />
    </Link>
);

export default BackButton;
