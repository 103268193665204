import styles from "./MediaViewer.module.css";
import { ZoomableImage } from "@faintlines/image";
import "@faintlines/image/dist/index.css";

import React from "react";
import classnames from "classnames";

export default function MediaViewer({ image, onClose }) {
    return (
        <div className={styles.viewer}>
            <div
                className={classnames(styles.closeButton, "icon-cancel")}
                onClick={onClose}
            />
            {image ? (
                <ZoomableImage
                    src={image}
                    className={styles.image}
                    showSpinner
                />
            ) : null}
        </div>
    );
}
