import styles from "./TitleView.module.css";
import BackButton from "./common/BackButton";

import React from "react";
import { Link } from "react-router-dom";
import urljoin from "url-join";

const TitleView = ({ title, titleName, appUrl }) => {
    const { pages, author, year, publisher, topics, text, image } = title;

    return (
        <div className={styles.titleView}>
            <BackButton url={appUrl} />
            <div
                className={styles.titleView__bg}
                style={{ backgroundImage: `url(${image})` }}
            >
                <div className={styles.titleView__bg__name}>{title.title}</div>
            </div>
            <div className={styles.titleView__details}>
                <Info exists={author}>{`Author: ${author}`}</Info>
                <Info exists={publisher}>{`Published By: ${publisher}`}</Info>
                <Info exists={year}>{`Publication Date: ${year}`}</Info>
                <Info exists={pages}>{`Print Length: ${pages} pages`}</Info>
                <Info exists={topics}>{`Topics: ${topics}`}</Info>
                <PreviewButton
                    isAvilable={text}
                    url={urljoin(appUrl, "title", titleName, "read")}
                />
            </div>
        </div>
    );
};

export default TitleView;

const PreviewButton = ({ isAvilable, url }) => (
    <div className={styles.previewButton}>
        <button
            type="button"
            className={isAvilable ? styles.avilable : null}
            disabled={!isAvilable}
        >
            <Link to={url}>{isAvilable ? "Preview" : "Not Available"}</Link>
        </button>
    </div>
);

const Info = ({ exists, children }) => {
    if (!exists) return null;
    return <div className={styles.info}>{children}</div>;
};
