import styles from "./Image.module.css";
import imageCache from "./imageCache";
import { selectImageSource } from "./imageUtils";

import React, { useState, useEffect } from "react";
import classnames from "classnames";

export default function Img({ src, ...props }) {
    return <ImgInner src={selectImageSource(src)} {...props} />;
}

const ImgInner = React.memo(
    ({ src, alt, className, showSpinner, onLoad, ...props }) => {
        const [loaded, setLoaded] = useState(imageCache.get(src));

        useEffect(() => {
            if (!loaded) {
                const image = new Image();

                image.onload = () => {
                    setLoaded(true);
                    imageCache.set(src, true);
                };
                image.onerror = () => {
                    setTimeout(() => {
                        image.src = src;
                    }, 500);
                };

                image.src = src;

                return () => {
                    image.onload = null;
                    image.onerror = null;
                };
            }
            return null;
        }, [src, loaded]);

        if (loaded) {
            return (
                <img
                    className={classnames(styles.image, className)}
                    src={src}
                    alt={alt}
                    onLoad={onLoad}
                    {...props}
                />
            );
        }

        return showSpinner ? <Spinner /> : null;
    }
);

const Spinner = () => (
    <div className={styles.spinner}>
        <div className={styles.spinner__inner} />
        <div className={styles.spinner__inner} />
        <div className={styles.spinner__inner} />
        <div className={styles.spinner__inner} />
    </div>
);
