import styles from "./Thread.module.css";
import MessageContent, { SystemMessage } from "./MessageContent";

import { isEmoji } from "@faintlines/emoji";
import { ContactPhoto } from "@faintlines/phone-sim-common";
import { Image } from "@faintlines/image";
import { formatDate, DATE_FORMAT } from "@faintlines/string-utils";

import React from "react";
import classnames from "classnames";

const Thread = ({ thread, conversation, onImageClick }) => (
    <div className={styles.thread}>
        <div className={styles.thread__date}>
            <div className={styles.thread__date__value}>
                {formatDate(thread.date, DATE_FORMAT)}
            </div>
        </div>
        {thread.messages.map((msg, i) => (
            <Message
                key={i}
                msg={msg}
                contact={conversation.contact || conversation}
                onImageClick={onImageClick}
            />
        ))}
    </div>
);

export default Thread;

export const Message = ({ msg, contact, onImageClick }) => {
    if (msg.system) {
        return <SystemMessage msg={msg} />;
    }

    return (
        <div className={classnames(styles.message, styles[msg.type])}>
            {msg.type === "in" ? (
                <ContactPhoto
                    className={styles.message__fromPhoto}
                    contact={contact}
                    size={30}
                />
            ) : null}
            <div
                id={msg.id}
                className={classnames(
                    styles.message__content,
                    msg.text ? styles.text : null,
                    msg.image ? styles.image : null,
                    msg.sticker ? styles.sticker : null,
                    msg.deleted ? styles.deleted : null,
                    msg.text && isEmoji(msg.text) ? styles.emoji : null,
                    msg.audio ? styles.audio : null
                )}
            >
                <MessageImage
                    src={msg.image || msg.sticker}
                    onClick={onImageClick}
                />

                <MessageContent msg={msg} />
            </div>
            <div className={styles.message__time}>{msg.time}</div>
        </div>
    );
};

const MessageImage = ({ src, onClick }) => {
    if (!src) {
        return null;
    }
    return (
        <Image
            className={styles.message__image}
            onClick={() => onClick(src)}
            src={src}
        />
    );
};
