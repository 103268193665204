import { useScroll } from "react-use";

export const useScrollerPosition = (scrollerRef) => {
    const { y } = useScroll(scrollerRef);

    const SCROLLING_SUBPIXEL_GRACE = 1;
    const scrollHeight = scrollerRef?.current?.scrollHeight || 0;
    const clientHeight = scrollerRef?.current?.clientHeight || 0;
    const isAtBottom =
        y + SCROLLING_SUBPIXEL_GRACE >= scrollHeight - clientHeight &&
        scrollHeight > clientHeight;
    const isAtTop = y <= 0;

    return [isAtBottom, isAtTop];
};
