// TODO: use @faintlines/native-bridge

export function vibrateShort() {
    vibrate(10);
}

export function vibrate(durationMs) {
    if (window.Android && window.Android.vibrate) {
        try {
            window.Android.vibrate(durationMs);
        } catch (e) {
            // nothing
        }
        return;
    }
    if (window.navigator && window.navigator?.vibrate) {
        window.navigator.vibrate(durationMs);
    }
}
