import audioMessageIn from "../media/audio/audio-message-in.mp3";

import { set, toJS, makeAutoObservable } from "mobx";
import { find, throttle } from "lodash";
import dayjs from "dayjs";
import { Howl } from "howler";

export default class MessagesAppStore {
    constructor({ existingState, app, mainState }) {
        makeAutoObservable(this);

        this._app = app;
        this._mainState = mainState;

        this._soundMessageIn = new Howl({
            src: [audioMessageIn],
            volume: 0.5,
        });

        if (MessagesAppStore.hasProgress(existingState)) {
            set(this, existingState);
        }

        this._playMessageInSound = throttle(() => {
            if (this._soundMessageIn && !this.isAudioPlaying) {
                this._soundMessageIn.play();
            }
        }, 1000);
    }

    dispose() {}

    isAudioPlaying = false;
    showArchived = false;
    keypadVisible = false;
    dynamicMessages = [];

    static hasProgress(state) {
        return (
            state && (state.showArchived || state.dynamicMessages?.length > 0)
        );
    }

    get asJson() {
        return {
            showArchived: toJS(this.showArchived),
            dynamicMessages: toJS(this.dynamicMessages),
        };
    }

    showArchivedMessages() {
        this.showArchived = true;
        this.toggleKeypad(false);
    }

    toggleKeypad(toggle) {
        this.keypadVisible = toggle;
    }

    pushMessage({ conversationId, contactName, text }) {
        const now = dayjs();

        let conversation = find(
            this.dynamicMessages,
            (x) => x.id === conversationId || x.name === contactName
        );
        if (!conversation) {
            conversation = {
                id: conversationId,
                name: contactName,
                threads: [{ date: now.format("MMM Do"), messages: [] }],
            };
            this.dynamicMessages.unshift(conversation);
            conversation = find(
                this.dynamicMessages,
                (x) => x.name === contactName
            );
        }

        conversation.threads[0].messages.push({
            time: now.format("hh:mm A"),
            type: "in",
            text,
        });

        if (!this.isAppActive) {
            this._mainState.pushNotification(this._app, "newMessage", {
                text: `${contactName}: ${text}`,
            });

            this._playMessageInSound();
        }
    }

    toggleAudioPlaying(toggle) {
        this.isAudioPlaying = toggle;
    }
}
