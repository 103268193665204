import styles from "./SearchPage.module.css";
import SEARCH_PLACEHOLDER from "../utils/searchPlacholder";

import { areStringsSimilar } from "@faintlines/string-utils";
import { Image } from "@faintlines/image";

import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import urljoin from "url-join";
import classnames from "classnames";
import { toPairs } from "lodash";
import { Search as IconSearch, X as IconX } from "lucide-react";

const SearchPage = ({ apps, appUrl }) => {
    const [query, setQuery] = useState("");
    const [results, setResults] = useState([]);
    const inputRef = useRef();

    useEffect(() => {
        inputRef.current.focus();
    }, []);

    const handleQueryChange = (evt) => {
        const newQuery = evt.target.value;
        setQuery(newQuery);

        const newResults = toPairs(apps).filter(([, app]) =>
            areStringsSimilar(app.name.toLowerCase(), newQuery.toLowerCase())
        );
        setResults(newResults);
    };

    return (
        <div className={styles.search}>
            <div className={styles.search__header}>
                <IconSearch
                    className={styles.search__header__icon}
                    size="1em"
                />
                <input
                    ref={inputRef}
                    className={styles.search__input}
                    type="text"
                    value={query}
                    onChange={handleQueryChange}
                    placeholder={SEARCH_PLACEHOLDER}
                />
                <Link
                    to={appUrl}
                    className={classnames(
                        styles.search__header__button,
                        query ? null : styles.hidden
                    )}
                >
                    <IconX className={styles.search__header__icon} size="1em" />
                </Link>
            </div>
            <div className={styles.search__results}>
                {query && results.length === 0 ? (
                    <div
                        className={styles.search__results__empty}
                    >{`No results for "${query}".`}</div>
                ) : null}
                {results.map(([appId, app]) => (
                    <Link
                        key={appId}
                        to={urljoin(appUrl, "app", appId)}
                        className={styles.search__result}
                    >
                        <Image
                            className={styles.search__result__image}
                            src={app.icon}
                            showSpinner
                            alt={app.name}
                        />
                        <div className={styles.search__result__name}>
                            {app.name}
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default SearchPage;
