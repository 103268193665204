import {
    replaceEmojisToArray,
    unicodeToCodepoint,
    codepointToTwemojiUrl,
} from "./emoji";

import React, { memo } from "react";

const EmojiText = memo(({ text }) => {
    if (!text) {
        return null;
    }
    const array = replaceEmojisToArray(text, emojiToImage);
    return <>{array}</>; // eslint-disable-line
});

export default EmojiText;

function emojiToImage(match, i) {
    const codepoint = unicodeToCodepoint(match);
    return (
        <img
            key={i}
            alt={match}
            src={codepointToTwemojiUrl(codepoint)}
            style={{
                height: "1.1em",
                width: "1.1em",
                margin: "0 0.075em",
                verticalAlign: "-20%",
            }}
            className="emoji"
        />
    );
}
