import styles from "./ContactPhoto.module.css";

import { Image } from "@faintlines/image";
import "@faintlines/image/dist/index.css";

import React from "react";
import classnames from "classnames";
import { sumBy } from "lodash";

const CONTACT_COLORS = [
    "#ffb5e8",
    "#b28dff",
    "#dcd3ff",
    "#aff8db",
    "#bffcc6",
    "#ffc9de",
    "#ff9cee",
    "#c5a3ff",
    "#a79aff",
    "#c4faf8",
    "#dbffd6",
    "#ffabab",
    "#d5aaff",
    "#b5b9ff",
    "#85e3ff",
    "#e7ffac",
    "#ffffd1",
    "#fff5ba",
    "#afcbff",
    "#ffbebc",
];

export default function ContactPhoto({ contact, className, size }) {
    return (
        <div
            className={classnames(styles.ContactPhoto, className)}
            style={{ width: size, height: size, fontSize: size / 2 }}
        >
            {contact.photo ? (
                <Image src={contact.photo} alt="" />
            ) : (
                <div
                    className={classnames(styles.ContactPhotoIcon, "icon-user")}
                    style={{ backgroundColor: contactColor(contact) }}
                />
            )}
        </div>
    );
}
ContactPhoto.defaultProps = {
    size: 40,
};

function contactColor(contact) {
    const num = sumBy(contact.name, (char) => char.charCodeAt(0));
    return CONTACT_COLORS[num % CONTACT_COLORS.length];
}
