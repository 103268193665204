import { find } from "lodash";

export const HAS_WEBP = hasWebpImageFormatSupport();

export function selectImageSource(src, hasWebp = HAS_WEBP) {
    if (Array.isArray(src)) {
        const webpSrc = find(src, (x) => x.includes(".webp"));
        const nonWebpSrc = find(src, (x) => !x.includes(".webp"));
        return webpSrc && hasWebp ? webpSrc : nonWebpSrc;
    }
    return src;
}

export function isImageUrl(url) {
    return !!(
        typeof url === "string" &&
        url.split("?")[0].match(/\.(jpe?g|png|svg|webp)$/)
    );
}

function hasWebpImageFormatSupport() {
    const elem = document.createElement("canvas");

    if (elem.getContext && elem.getContext("2d")) {
        // was able or not to get WebP representation
        return elem.toDataURL("image/webp").indexOf("data:image/webp") === 0;
    }

    // very old browser like IE 8, canvas not supported
    return false;
}
