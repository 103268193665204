import { values, isEmpty } from "lodash";

const mediaSource = window.MediaSource || window.WebKitMediaSource;
const sourceBuffer = window.SourceBuffer || window.WebKitSourceBuffer;

function hasNativeHlsSupport() {
    const video = document.createElement("video");
    return Boolean(
        video.canPlayType("application/vnd.apple.mpegURL") ||
            video.canPlayType("audio/mpegurl")
    );
}

function hasHlsJsSupport() {
    // based on https://github.com/video-dev/hls.js/blob/c35441b94f7ffef94e12ecf99aa7a5c61d5759a0/src/is-supported.ts#L8

    if (!mediaSource) {
        return false;
    }
    const isTypeSupported =
        mediaSource &&
        typeof mediaSource.isTypeSupported === "function" &&
        mediaSource.isTypeSupported(
            'video/mp4; codecs="avc1.42E01E,mp4a.40.2"'
        );

    // if SourceBuffer is exposed ensure its API is valid
    // safari and old version of Chrome doe not expose SourceBuffer globally so checking SourceBuffer.prototype is impossible
    const sourceBufferValidAPI =
        !sourceBuffer ||
        (sourceBuffer.prototype &&
            typeof sourceBuffer.prototype.appendBuffer === "function" &&
            typeof sourceBuffer.prototype.remove === "function");
    return !!isTypeSupported && !!sourceBufferValidAPI;
}

export const SUPPORTS_HLS = hasNativeHlsSupport() || hasHlsJsSupport();

// https://github.com/Dash-Industry-Forum/dash.js/issues/2055#issuecomment-313187528
export const SUPPORTS_DASH =
    mediaSource && typeof mediaSource.isTypeSupported === "function";

export function filterVideoUrls(urls) {
    return urls.filter((url) => isVideoUrl(url));
}

export function isVideoUrl(url, rejectObjects) {
    return !!(
        (typeof url === "string" && /.*\.(mp4|m3u8)/.test(url)) ||
        (typeof url === "object" &&
            !rejectObjects &&
            !isEmpty(url) &&
            values(url).every((value) => isVideoUrl(value, true)))
    );
}
