import styles from "./MessageContent.module.css";
import MessageAudioPlayer from "./MessageAudioPlayer";

import { useMarkdownContent } from "@faintlines/markdown";

import React from "react";
import classnames from "classnames";

const MessageContent = ({ msg }) => {
    const MarkdownContent = useMarkdownContent();

    if (msg.audio) {
        return <MessageAudioPlayer src={msg.audio} />;
    }
    if (msg.deleted) {
        return <DeletedMessage />;
    }
    return (
        <MarkdownContent markdown={msg.text} className={styles.message__text} />
    );
};

export default MessageContent;

export const SystemMessage = ({ msg }) => (
    <div className={styles.system}>{msg.text}</div>
);

const DeletedMessage = () => (
    <>
        <i
            className={classnames(styles.message__deletedIcon, "icon-no-sign")}
        />
        {"You deleted this message"}
    </>
);
