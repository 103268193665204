const { Android } = window;
const iOS = window.webkit?.messageHandlers?.iOS;

export const HAVE_ANDROID = !!Android;
export const HAVE_IOS = !!iOS;
export const HAVE_NATIVE = HAVE_ANDROID || HAVE_IOS;

export const APP_STORE_NAME = HAVE_ANDROID
    ? "Play Store"
    : HAVE_IOS
    ? "App Store"
    : null;

export const PLATFORM_NAME = HAVE_ANDROID ? "Android" : HAVE_IOS ? "iOS" : null;

export const CAPABILITIES = HAVE_NATIVE
    ? {
          getAppVersion: HAVE_IOS || !!Android.getAppVersion,
          setUserId: HAVE_IOS || !!Android.setUserId,
          setUserProperties: HAVE_IOS || !!Android.setUserProperties,
          openUrlInBrowser: HAVE_IOS || !!Android?.openUrlInBrowser,
          vibrate: HAVE_IOS || !!Android?.vibrate,
          showVideoAd: HAVE_IOS || !!Android?.showVideoAd,
          dialPhoneNumber: HAVE_IOS || !!Android?.dialPhoneNumber,
          rateInStore: HAVE_IOS || !!Android?.rateInStore,
          share: HAVE_IOS || !!Android?.share,
          buyStoreItem: HAVE_IOS || !!Android?.buyStoreItem,
          fetchStoreSKUs: HAVE_IOS || !!Android?.fetchStoreSKUs,
          listPurchases: HAVE_IOS || !!Android?.listPurchases,
          ackStoreItem: HAVE_IOS || !!Android?.ackStoreItem,
          logCustomAnalyitcEvent: HAVE_IOS || !!Android?.logCustomAnalyitcEvent,
          requestTrackingAuthorization: HAVE_IOS,
          requestPushNotificationsAuthorization: HAVE_IOS,
          loginWithFacebook: HAVE_IOS || !!Android?.loginWithFacebook,
          loginWithGoogle: HAVE_IOS || !!Android?.loginWithGoogle,
          loginWithApple: HAVE_IOS,
      }
    : {};

export function getAppVersion() {
    return callNativeFunc("getAppVersion");
}

export function setUserId(userId) {
    return callNativeFunc("setUserId", [userId], { userId });
}

export function setUserProperties(props) {
    const propsJson = JSON.stringify(props);
    return callNativeFunc("setUserProperties", [propsJson], { props });
}

export function openUrlInBrowser(url) {
    return callNativeFunc("openUrlInBrowser", [url], { url });
}

export function vibrate(durationMs) {
    return callNativeFunc("vibrate", [durationMs], { durationMs });
}

export function dialPhoneNumber(phoneNumber, onFailure) {
    // TODO: move exception handling to callNativeFunc?
    let result;
    try {
        result = callNativeFunc("dialPhoneNumber", [`tel:${phoneNumber}`], {
            phoneNumber,
        });
    } catch (e) {
        result = false;
    }

    if (result === false && onFailure) {
        onFailure();
    }
}

export function showVideoAd(adUnitId) {
    return callNativeFunc("showVideoAd", [adUnitId], { adUnitId });
}

export function rateInStore() {
    return callNativeFunc("rateInStore");
}

export function share(text, url) {
    return callNativeFunc("share", [`${text} ${url}`], { text, url });
}

export function buyStoreItem(itemId) {
    return callNativeFunc("buyStoreItem", [itemId], { itemId });
}

export function fetchStoreSKUs(skus) {
    return callNativeFunc("fetchStoreSKUs", [skus], { skus });
}

export function listPurchases() {
    return callNativeFunc("listPurchases");
}

export function ackStoreItem(token) {
    return callNativeFunc("ackStoreItem", [token], { token });
}

export function logCustomAnalyitcEvent(
    category,
    action,
    label,
    value,
    nonInteraction
) {
    return callNativeFunc(
        "logCustomAnalyitcEvent",
        [category, action, label, value, nonInteraction],
        { category, action, label, value, nonInteraction }
    );
}

export function requestTrackingAuthorization() {
    return callNativeFunc("requestTrackingAuthorization");
}

export function requestPushNotificationsAuthorization() {
    return callNativeFunc("requestPushNotificationsAuthorization");
}

export function loginWithFacebook() {
    return callNativeFunc("loginWithFacebook");
}

export function loginWithGoogle() {
    return callNativeFunc("loginWithGoogle");
}

export function loginWithApple() {
    return callNativeFunc("loginWithApple");
}

function callNativeFunc(funcName, androidParams, iOSParams) {
    if (!CAPABILITIES[funcName]) {
        return undefined;
    }

    if (HAVE_ANDROID) {
        return Android[funcName](...(androidParams || []));
    }
    if (HAVE_IOS) {
        return iOSCallFunction(funcName, iOSParams);
    }

    return undefined;
}

function iOSCallFunction(funcName, params) {
    return iOS.postMessage(
        JSON.stringify({ func: funcName, ...(params || {}) })
    );
}
