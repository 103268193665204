import { selectImageSource } from "./imageUtils";
import imageCache from "./imageCache";

import { useEffect, useRef, memo } from "react";
import { sum, isEqual } from "lodash";

function ImagePreloaderInner({ images, onLoaded }) {
    const imageObjects = useRef();

    useEffect(() => {
        const numImages = (images || []).length;

        if (numImages === 0) {
            if (onLoaded) {
                onLoaded();
            }
            return;
        }

        const loadedImages = [];
        imageObjects.current = images.map((url, i) => {
            const img = new Image();
            img.addEventListener("load", () => {
                loadedImages[i] = 1;
                imageCache.set(img.src, true);

                if (sum(loadedImages) === numImages) {
                    if (onLoaded) {
                        onLoaded();
                    }
                }
            });
            img.src = selectImageSource(url);
            return img;
        });
    }, [images, onLoaded]);

    return null;
}

export default memo(ImagePreloaderInner, isEqual);
