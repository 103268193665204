import MarkdownContent from "./MarkdownContent";

import React from "react";

// TODO: use @faintlines/native-bridge
const NATIVE_OPEN_URL =
    typeof window !== "undefined" ? window.Android?.openUrlInBrowser : null;

export default function EnhancedMarkdownContent({
    onLinkClick,
    openLinksInNativeBrowser,
    ...props
}) {
    const handleLinkClick = (evt) => {
        if (onLinkClick) {
            onLinkClick(evt);
        }

        if (evt.target.href && !evt.defaultPrevented && NATIVE_OPEN_URL) {
            evt.preventDefault();
            NATIVE_OPEN_URL(evt.target.href);
        }
    };

    return <MarkdownContent {...props} onLinkClick={handleLinkClick} />;
}
EnhancedMarkdownContent.defaultProps = {
    openLinksInNativeBrowser: true,
};
