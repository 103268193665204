import styles from "./AppStaticImage.module.css";

import { Image, ZoomableImage } from "@faintlines/image";

import React from "react";
import classnames from "classnames";

const AppStaticImage = ({
    imageUrl,
    backgroundColor,
    backgroundImage,
    size,
    align,
    zoomable, // boolean or props object
    showSpinner,
}) => {
    if (!imageUrl) {
        return null;
    }

    const ImageComponent = zoomable ? ZoomableImage : Image;
    const zoomableProps = typeof zoomable === "object" ? zoomable : {};

    return (
        <div
            className={classnames(
                styles.app,
                styles[align],
                zoomable ? styles.zoomable : null
            )}
            style={{ backgroundColor, backgroundImage }}
        >
            <ImageComponent
                src={imageUrl}
                className={classnames(
                    styles.image,
                    styles[size],
                    styles[align]
                )}
                showSpinner={showSpinner}
                {...zoomableProps}
            />
        </div>
    );
};

AppStaticImage.defaultProps = {
    backgroundImage: "none",
    backgroundColor: "transparent",
    size: "contain",
    align: "center",
    zoomable: false,
    showSpinner: true,
};

export default AppStaticImage;
