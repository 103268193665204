import { range, clone, shuffle, without } from "lodash";
import React, { useState } from "react";

export default function TilePuzzle({
    width,
    height,
    rows,
    cols,
    hole,
    image,
    className,
    onSolved,
}) {
    const [numbers, setNumbers] = useState(
        shuffleNumbers(range(0, rows * cols), hole, rows, cols)
    );

    const handleClick = (tileIndex) => {
        const holeIndex = numbers.indexOf(hole);
        if (canSwap(tileIndex, holeIndex, rows, cols)) {
            const newNumbers = swap(numbers, tileIndex, holeIndex);
            setNumbers(newNumbers);
            if (isSolved(newNumbers) && onSolved) {
                onSolved();
            }
        }
    };

    const pieceWidth = Math.round(width / cols);
    const pieceHeight = Math.round(height / rows);
    const style = {
        ...tilesStyle,
        width,
        height,
    };

    return (
        <div className={className}>
            <ul style={style}>
                {numbers.map((number, index) => (
                    <Tile
                        hole={hole}
                        rows={rows}
                        cols={cols}
                        index={index}
                        number={number}
                        key={number}
                        width={pieceWidth}
                        height={pieceHeight}
                        image={image}
                        onClick={handleClick}
                    />
                ))}
            </ul>
        </div>
    );
}
TilePuzzle.defaultProps = {
    width: 300,
    height: 300,
    rows: 3,
    cols: 3,
    hole: 8,
};

const Tile = ({
    hole,
    number,
    index,
    rows,
    cols,
    width,
    height,
    image,
    onClick,
}) => {
    const matrixPos = getMatrixPosition(index, rows, cols);
    const visualPos = getVisualPosition(matrixPos, width, height);
    const style = {
        ...tileStyle,
        ...(number === hole ? holeStyle : {}),
        width,
        height,
        backgroundImage: image ? `url(${image})` : null,
        backgroundRepeat: "no-repeat",
        backgroundSize: `${width * cols}px ${height * rows}px`,
        backgroundPosition: `${((number % cols) / (cols - 1)) * 100}% ${
            (Math.floor(number / rows) / (rows - 1)) * 100
        }%`,
    };

    return (
        <div
            style={{
                ...style,
                transform: `translate3d(${visualPos.x}px, ${visualPos.y}px, 0)`,
            }}
            onClick={() => onClick(index)}
        />
    );
};

const tilesStyle = {
    listStyle: "none",
    margin: "0 auto",
    padding: 0,
    position: "relative",
};

const tileStyle = {
    backgroundColor: "grey",
    boxSizing: "border-box",
    display: "block",
    padding: 6,
    position: "absolute",
};

const holeStyle = {
    opacity: 0,
};

// Checks if the puzzle is solved.
//
// Examples:
//   isSolved([6, 4, 5, 0, 1, 2, 3, 7, 8]) // => false
//   isSolved([0, 1, 2, 3, 4, 5, 6, 7, 8]) // => true
export function isSolved(numbers) {
    for (let i = 0; i < numbers.length; i += 1) {
        if (numbers[i] !== i) {
            return false;
        }
    }
    return true;
}

// Get the row/col pair from a linear index.
export function getMatrixPosition(index, rows, cols) {
    return {
        row: Math.floor(index / cols),
        col: index % cols,
    };
}

export function getVisualPosition({ row, col }, width, height) {
    return {
        x: col * width,
        y: row * height,
    };
}

export function canSwap(src, dest, rows, cols) {
    const { row: srcRow, col: srcCol } = getMatrixPosition(src, rows, cols);
    const { row: destRow, col: destCol } = getMatrixPosition(dest, rows, cols);
    return Math.abs(srcRow - destRow) + Math.abs(srcCol - destCol) === 1;
}

export function swap(numbers, src, dest) {
    const newNumbers = clone(numbers);
    [newNumbers[src], newNumbers[dest]] = [newNumbers[dest], newNumbers[src]];
    return newNumbers;
}

export function shuffleNumbers(numbers, hole, rows, cols) {
    let shuffled = null;
    do {
        shuffled = shuffle(without(numbers, hole)).concat(hole);
    } while (isSolved(shuffled) || !isSolvable(shuffled, rows, cols));
    return shuffled;
}

export function isSolvable(numbers, rows, cols) {
    let product = 1;
    for (let i = 1, l = rows * cols - 1; i <= l; i += 1) {
        for (let j = i + 1, m = l + 1; j <= m; j += 1) {
            product *= (numbers[i - 1] - numbers[j - 1]) / (i - j);
        }
    }
    return Math.round(product) === 1;
}
