import styles from "./AppBooks.module.css";
import IndexView from "./components/IndexView";
import TitleView from "./components/TitleView";
import ReadView from "./components/ReadView";

import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import urljoin from "url-join";

const AppBooks = ({ sections, titles, appUrl, appPath }) => {
    const getSelectedTitle = (routeProps) => {
        const { titleName } = routeProps.match.params;
        return { name: titleName, values: titles[titleName] };
    };

    return (
        <div className={styles.app}>
            <Switch>
                <Route
                    exact
                    path={appPath}
                    render={() => (
                        <IndexView
                            sections={sections}
                            titles={titles}
                            appUrl={appUrl}
                        />
                    )}
                />
                <Route
                    exact
                    path={urljoin(appPath, "title", ":titleName")}
                    render={(routeProps) => (
                        <TitleView
                            title={getSelectedTitle(routeProps).values}
                            titleName={getSelectedTitle(routeProps).name}
                            appUrl={appUrl}
                        />
                    )}
                />
                <Route
                    exact
                    path={urljoin(appPath, "title", ":titleName", "read")}
                    render={(routeProps) => (
                        <ReadView
                            title={getSelectedTitle(routeProps).values}
                            titleName={getSelectedTitle(routeProps).name}
                            appUrl={appUrl}
                        />
                    )}
                />
                <Route>
                    <Redirect to={appUrl} />
                </Route>
            </Switch>
        </div>
    );
};

export default AppBooks;
