export function calcTileDimensions(screenWidth) {
    if (!screenWidth) {
        return {};
    }

    const tilesPerRow = screenWidth < 340 ? 2.8 : 3.8;
    const width = screenWidth / tilesPerRow;
    const height = width * 1.5;
    return { width, height };
}
