import levenshtein from "js-levenshtein";
import dayjs from "dayjs";

const MILLION = 1000 * 1000;
const THOUSAND = 1000;
const WORD_VARIANT_LEV_DIST_RATIO = 0.2;

export const DATE_FORMAT = "MMM D";

export function rsplit(string, sep, maxsplit) {
    const split = string.split(sep);
    return maxsplit
        ? [split.slice(0, -maxsplit).join(sep)].concat(split.slice(-maxsplit))
        : split;
}

export function limitString(string, limit) {
    if (!string) {
        return string;
    }
    if (string.length <= limit) {
        return string;
    }
    const truncated = string.substring(0, limit);
    return `${truncated}...`;
}

export function replaceCaseInsensitive(str, strReplace, strWith) {
    const esc = strReplace.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
    const reg = new RegExp(esc, "ig");
    return str.replace(reg, strWith);
}

export function pluralize(num, pluralForm, singularForm) {
    return num === 1 ? singularForm || "" : pluralForm || "s";
}

export function escapeLunrQueryString(queryString) {
    if (typeof queryString !== "string") {
        return queryString;
    }
    return queryString.replace(/[-~^:+]/g, "\\$&");
}

export function formatIntegerHuman(number) {
    if (number === null || Number.isNaN(number)) {
        return number;
    }
    const num = typeof number === "string" ? parseInt(number, 10) : number;
    if (Number.isNaN(num)) {
        return number;
    }
    if (num < THOUSAND) {
        return num.toString();
    }
    let units;
    let amount;
    if (num >= MILLION) {
        units = "M";
        amount = num / MILLION;
    } else {
        units = "K";
        amount = num / THOUSAND;
    }
    if (amount % 1 < 0.1) {
        return `${Math.round(amount)}${units}`;
    }
    return `${amount.toFixed(1)}${units}`;
}

export function areStringsSimilar(str1, str2) {
    const minLength = Math.min(str1.length, str2.length);
    const maxDist = Math.round(minLength * WORD_VARIANT_LEV_DIST_RATIO);
    if (maxDist === 0) {
        return str1 === str2;
    }
    return levenshtein(str1, str2) <= maxDist;
}

export function stringsInclude(strings, value, options) {
    const { caseSensitive, allowTypos } = options || {};
    let stringArr = Array.isArray(strings) ? strings : [strings];
    let valueNorm = value;

    if (!caseSensitive) {
        stringArr = stringArr.map((x) => x.toLowerCase());
        valueNorm = valueNorm.toLowerCase();
    }

    if (!allowTypos) {
        return stringArr.includes(valueNorm);
    }

    return stringArr.some((x) => areStringsSimilar(x, valueNorm));
}

export function formatDate(date, format) {
    if (typeof date === "string") {
        return date;
    }

    if (
        typeof date === "object" &&
        date !== null &&
        date.__type === "RDT" && // eslint-disable-line
        dayjs
    ) {
        date = dayjs() // eslint-disable-line
            .subtract(date.amount || 0, date.units || "minutes")
            .hour(0)
            .minute(0)
            .add(date.time || 0, "minutes");
    }

    if (typeof date?.format === "function") {
        return date.format(format);
    }

    return "";
}
