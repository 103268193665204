import emojiRegexFactory from "emoji-regex/RGI_Emoji";
import replaceToArray from "string-replace-to-array";

// https://github.com/twitter/twemoji/blob/gh-pages/2/twemoji.js#L232
const UFE0Fg = /\uFE0F/g;

// \u200D is a zero-width joiner character
// https://github.com/twitter/twemoji/blob/gh-pages/2/twemoji.js#L235
const U200D = String.fromCharCode(0x200d);

export const emojiRegex = emojiRegexFactory();

export function isEmoji(string) {
    if (!string) {
        return false;
    }
    const match = string.match(emojiRegex);
    return match !== null && match.length > 0 && match[0] === string;
}

export function replaceEmojisToArray(text, replacement) {
    return replaceToArray(text, emojiRegex, replacement);
}

export function replaceEmojis(text, replacement) {
    if (!text) {
        return text;
    }
    return text.replace(emojiRegex, replacement);
}

export function codepointToTwemojiUrl(codepoint) {
    return `https://twemoji.maxcdn.com/v/13.1.0/72x72/${codepoint}.png`;
}

export function unicodeToCodepoint(input) {
    return toCodePoint(
        input.indexOf(U200D) < 0 ? input.replace(UFE0Fg, "") : input
    );
}

function toCodePoint(input, separator = "-") {
    const codePoints = Array.from(input).map((codePoint) =>
        codePoint.codePointAt(0).toString(16)
    );
    return codePoints.join(separator);
}
