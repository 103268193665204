import styles from "./AppPage.module.css";

import { useAppStore } from "@faintlines/phone-sim-common";
import { Image } from "@faintlines/image";
import { useMarkdownContent } from "@faintlines/markdown";

import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import classnames from "classnames";

const AppPage = observer(({ app, appId }) => {
    const { name, developer, icon, details, downloadable } = app;
    const store = useAppStore();
    const [downloadError, setDownloadError] = useState(null);
    const MarkdownContent = useMarkdownContent();

    const downloaded = store.downloaded[appId];
    const downloading = store.downloading[appId];
    const canDownload = !downloaded && !downloading;

    const handleDownload = () => {
        if (!downloadable) {
            setDownloadError("No space left on device.");
            return;
        }
        store.downloadApp(app, appId);
    };

    return (
        <div className={styles.appPage}>
            <div className={styles.appPage__header}>
                <div className={styles.appPage__icon}>
                    <Image
                        className={styles.appPage__icon__image}
                        src={icon}
                        showSpinner
                        alt={name}
                    />
                </div>
                <div className={styles.appPage__header__info}>
                    <div className={styles.appPage__title}>{name}</div>
                    {developer ? (
                        <div className={styles.appPage__developer}>
                            {developer}
                        </div>
                    ) : null}
                </div>
            </div>
            <div
                className={classnames(
                    styles.appPage__downloadButton,
                    canDownload ? null : styles.disabled
                )}
                onClick={canDownload ? handleDownload : null}
            >
                {downloaded
                    ? "Installed"
                    : downloading
                    ? "Downloading..."
                    : "Download"}
            </div>
            {downloadError ? (
                <div className={styles.appPage__downloadError}>
                    {downloadError}
                </div>
            ) : null}
            <div className={styles.appPage__infoTitle}>
                {"More details about this app"}
            </div>
            <MarkdownContent
                className={styles.appPage__info}
                markdown={details || "No additional details."}
            />
        </div>
    );
});

export default AppPage;
