import { set, toJS, makeAutoObservable, runInAction } from "mobx";
import { isEmpty } from "lodash";

export const DOWNLOAD_TIME_MS = 3000;

export default class DownloaderAppStore {
    constructor({ existingState, onEvent }) {
        makeAutoObservable(this);

        this._onEvent = onEvent;

        if (DownloaderAppStore.hasProgress(existingState)) {
            set(this, existingState);
        }
    }

    dispose() {}

    downloading = {};
    downloaded = {};

    static hasProgress(state) {
        return state && !isEmpty(state.downloaded);
    }

    get asJson() {
        return {
            downloaded: toJS(this.downloaded),
        };
    }

    downloadApp(app, appId) {
        if (this.downloaded[appId]) {
            return;
        }
        this.downloading[appId] = true;
        setTimeout(
            () =>
                runInAction(() => {
                    if (this._onEvent) {
                        this._onEvent(app.downloadEvent);
                    }
                    this.downloaded[appId] = true;
                    this.downloading[appId] = false;
                }),
            DOWNLOAD_TIME_MS
        );
    }
}
