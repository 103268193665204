import styles from "./ReadView.module.css";
import BackButton from "./common/BackButton";

import React from "react";
import { useMarkdownContent } from "@faintlines/markdown";
import urljoin from "url-join";

const ReadView = ({ title, titleName, appUrl }) => {
    const MarkdownContent = useMarkdownContent();
    const { text } = title;

    if (!text) {
        return null;
    }

    return (
        <div className={styles.readView}>
            <BackButton url={urljoin(appUrl, "title", titleName)} />
            <MarkdownContent
                className={styles.readView__markdown}
                markdown={text}
            />
        </div>
    );
};

export default ReadView;
