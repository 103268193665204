import styles from "./PasswordInput.module.css";

import React, { useState } from "react";
import classnames from "classnames";
import { Eye as IconEye, EyeOff as IconEyeOff } from "lucide-react";

// A password field trying to prevent password manager auto fill by:
// 1. enclosing in a <form> with autocomplete="off"
// 2. data-lpignore="true" to disable LastPass
// 3. autocomplete="new-password" (https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete)
// 4. adding "search" to the class name to make lastpass think it's a search field

export default function PasswordInput({
    className,
    formClassName,
    showPassPostion,
    color,
    ...props
}) {
    const [isVisible, setIsVisible] = useState(false);

    const Icon = isVisible ? IconEyeOff : IconEye;

    return (
        <form
            autoComplete="off"
            action="#"
            className={classnames(styles.form, formClassName)}
            onSubmit={(evt) => evt.preventDefault()}
        >
            <input
                {...props}
                className={classnames(
                    className,
                    "lastpass-fake-search",
                    styles.input
                )}
                style={{ color }}
                type={isVisible ? "text" : "password"}
                data-lpignore="true"
                autoComplete="new-password"
            />
            <Icon
                className={styles.visibleIcon}
                style={{ color }}
                onClick={() => setIsVisible(!isVisible)}
            />
        </form>
    );
}
