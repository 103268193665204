import React from "react";

const AppStoreContext = React.createContext(null);

export const AppStoreProvider = ({ store, children }) => (
    <AppStoreContext.Provider value={store}>
        {children}
    </AppStoreContext.Provider>
);

export const useAppStore = () => React.useContext(AppStoreContext);
