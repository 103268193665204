import styles from "./ScrollToTopButton.module.css";

import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { ChevronsUp as IconChevronsUp } from "lucide-react";

export const ScrollToTopButton = ({
    color,
    backgroundColor,
    size,
    visible,
    onClick,
}) => {
    const [displayFirst, setDisplayFirst] = useState(null);
    useEffect(() => {
        if (visible && displayFirst === null) {
            setTimeout(() => setDisplayFirst(true), 10);
        }
        if (!visible && displayFirst) {
            setDisplayFirst(false);
        }
    }, [visible, displayFirst]);

    return (
        <div
            className={classnames(
                styles.ScrollToTopButton,
                visible && displayFirst ? styles.visible : null
            )}
            onClick={onClick}
            style={{
                backgroundColor,
                width: size,
                height: size,
                fontSize: size / 2.5,
            }}
        >
            <IconChevronsUp
                size="1.5em"
                className={styles.icon}
                style={{ color }}
            />
        </div>
    );
};
ScrollToTopButton.defaultProps = {
    size: 40,
};
