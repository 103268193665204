import styles from "./AppStaticText.module.css";
import { useMarkdownContent } from "@faintlines/markdown";
import React from "react";

export default function AppStaticText({
    text,
    textStyle,
    backgroundColor,
    backgroundImage,
}) {
    const MarkdownContent = useMarkdownContent();

    return (
        <div
            className={styles.app}
            style={{ backgroundColor, backgroundImage }}
        >
            <MarkdownContent
                className={styles.text}
                markdown={text}
                style={textStyle}
            />
        </div>
    );
}
