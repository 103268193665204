import VideoPlayer from "./VideoPlayer";

import React, { memo } from "react";
import { isEqual } from "lodash";

function VideoPreloaderInner({ urls }) {
    if ((urls || []).length === 0) {
        return null;
    }

    return (
        <div>
            {(urls || []).map((url) => (
                <VideoPlayer key={url} url={url} preload="auto" muted />
            ))}
        </div>
    );
}

export default memo(VideoPreloaderInner, isEqual);
