import { random } from "lodash";

export function randomChoice(array) {
    return array[random(array.length - 1)];
}

export function weightedChoice(array, randomFunc) {
    // array: [["a", 1], ["b", 2]]

    const total = array.reduce((sum, x) => sum + x[1], 0);
    const threshold = (randomFunc || Math.random)() * total;

    let upto = 0;
    for (let i = 0; i < array.length; i += 1) {
        const [choice, weight] = array[i];
        upto += weight;
        if (upto >= threshold) {
            return choice;
        }
    }

    // should not get here
    return undefined;
}

export function randomNormalInt(mu, sigma, seed) {
    return Math.round(randomNormalGenerator(mu, sigma, seed)());
}

export function randomNormalGenerator(mu, sigma, seed) {
    /* eslint-disable */
    const source = WeakPRNG(seed);

    var x, r;
    mu = mu == null ? 0 : +mu;
    sigma = sigma == null ? 1 : +sigma;

    return function () {
        var y;

        // If available, use the second previously-generated uniform random.
        if (x != null) {
            y = x;
            x = null;
        }
        // Otherwise, generate a new x and y.
        else
            do {
                x = source() * 2 - 1;
                y = source() * 2 - 1;
                r = x * x + y * y;
            } while (!r || r > 1);

        return mu + sigma * y * Math.sqrt((-2 * Math.log(r)) / r);
    };
    /* eslint-enable */
}

export function WeakPRNG(seed) {
    let current = seed;
    return () => {
        const x = Math.sin(current) * 10000;
        current += 1;
        return x - Math.floor(x);
    };
}

export function seedFromString(str) {
    let hash = 0;
    if (str.length === 0) return hash;
    for (let i = 0; i < str.length; i += 1) {
        const chr = str.charCodeAt(i);
        /* eslint-disable */
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
        /* eslint-enable */
    }
    return hash;
}
