import styles from "./Conversation.module.css";
import Thread from "./Thread";
import BackButton from "./common/BackButton";

import { limitString } from "@faintlines/string-utils";
import { EmojiText } from "@faintlines/emoji";
import { find } from "lodash";

import React, { useEffect, useRef, useState } from "react";

const Conversation = ({ conversationId, conversations, ...props }) => {
    const [unloadingConvId, setUnloadingConvId] = useState(null);

    useEffect(() => {
        if (conversationId) {
            setUnloadingConvId(conversationId);
            return;
        }

        const timeout = setTimeout(() => setUnloadingConvId(null), 500);
        return () => clearTimeout(timeout); // eslint-disable-line
    }, [conversationId]);

    const actualConvId = conversationId || unloadingConvId;

    if (!actualConvId) {
        return null;
    }

    // backwards compatibility when conversationId was an index.
    const conversation =
        find(conversations, (x) => x.id === actualConvId) ||
        conversations[actualConvId];

    if (!conversation) {
        return null;
    }
    return <ConversationContent conversation={conversation} {...props} />;
};

export default Conversation;

const ConversationContent = ({
    conversation,
    appUrl,
    onImageClick,
    onEvent,
}) => {
    const { threads, readEvent } = conversation;
    const { name, number } = conversation.contact || conversation;
    const scroller = useRef();

    useEffect(() => {
        scroller.current.scrollTop = scroller.current.scrollHeight;
    }, []); // TODO: not always?

    useEffect(() => {
        if (readEvent && onEvent) {
            onEvent(readEvent);
        }
    }, [readEvent, onEvent]);

    return (
        <div className={styles.conversation}>
            <div className={styles.conversation__header}>
                <BackButton url={appUrl} />
                <div className={styles.conversation__details}>
                    <div className={styles.conversation__details__name}>
                        <EmojiText text={limitString(name || number, 40)} />
                    </div>
                    {number && name ? (
                        <div className={styles.conversation__details__number}>
                            {number}
                        </div>
                    ) : null}
                </div>
            </div>
            <div
                className={styles.conversation__messagesWrapper}
                ref={scroller}
            >
                <div className={styles.conversation__messages}>
                    {threads.map((thread, i) => (
                        <Thread
                            key={i}
                            thread={thread}
                            conversation={conversation}
                            onImageClick={onImageClick}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};
