import { set, makeAutoObservable, toJS } from "mobx";
import { isEmpty, find } from "lodash";

export default class SettingsAppStore {
    constructor({ app, existingState }) {
        makeAutoObservable(this);

        this._app = app;

        if (SettingsAppStore.hasProgress(existingState)) {
            set(this, existingState);
        }
    }

    // eslint-disable-next-line
    dispose() {}

    keypad = false;
    toggleValues = {};

    static hasProgress(state) {
        return state && !isEmpty(state.toggleValues);
    }

    // eslint-disable-next-line
    get asJson() {
        return {
            toggleValues: toJS(this.toggleValues),
        };
    }

    getToggleValue(id) {
        const value = this.toggleValues[id];
        return value === undefined
            ? find(this._app.data.settings, (x) => x.id === id).defaultValue
            : value;
    }

    toggleValue(id) {
        this.toggleValues[id] = !this.getToggleValue(id);
    }

    showKeypad(keypad) {
        this.keypad = keypad;
    }

    hideKeypad() {
        this.keypad = null;
    }
}
