import { useState, useRef, useCallback } from "react";

export const useScroller = () => {
    const scroller = useRef(null);
    const [scrollerChildrenStyle, setScrollerChildrenStyle] = useState({});

    const scrollToTop = useCallback((durationMs) => {
        if (!scroller.current) {
            return;
        }
        const distanceToTop =
            scroller.current.scrollHeight - scroller.current.clientHeight;
        setScrollerChildrenStyle({
            bottom: 0,
            position: "absolute",
            transform: `translateY(${distanceToTop}px)`,
            transition: `transform ${durationMs}ms ease-in-out`,
        });
        setTimeout(() => {
            setScrollerChildrenStyle({});
        }, durationMs);
    }, []);

    const scrollToBottom = useCallback(() => {
        if (!scroller.current) {
            return;
        }
        scroller.current.scrollTop = scroller.current.scrollHeight;
    }, []);

    return [scroller, scrollerChildrenStyle, scrollToBottom, scrollToTop];
};
