import styles from "./MessagesIndex.module.css";

import {
    limitString,
    pluralize,
    formatDate,
    DATE_FORMAT,
} from "@faintlines/string-utils";
import { EmojiText } from "@faintlines/emoji";
import { useAppStore, ContactPhoto } from "@faintlines/phone-sim-common";

import React from "react";
import { Link } from "react-router-dom";
import urljoin from "url-join";
import { last } from "lodash";
import { observer } from "mobx-react-lite";

const MessagesIndex = ({
    conversations,
    archivedConversations,
    appTitle,
    appUrl,
}) => {
    const store = useAppStore();
    const { showArchived } = store;

    return (
        <div className={styles.index}>
            <div className={styles.index__scroller}>
                <MessageIndexHeader appTitle={appTitle} />
                <MessagesIndexList
                    conversations={conversations}
                    appUrl={appUrl}
                />
                {archivedConversations ? (
                    showArchived ? (
                        <>
                            <div className={styles.archivedTitle}>
                                {"Archived Conversations"}
                            </div>
                            <MessagesIndexList
                                conversations={archivedConversations}
                                appUrl={appUrl}
                                startIndex={conversations.length}
                            />
                        </>
                    ) : (
                        <ShowArchivedButton
                            numConversations={archivedConversations.length}
                            onClick={() => store.toggleKeypad(true)}
                        />
                    )
                ) : null}
            </div>
        </div>
    );
};

export default observer(MessagesIndex);

const MessagesIndexList = ({ conversations, appUrl, startIndex }) => (
    <div className={styles.messageIndexList}>
        {conversations.map((conversation, i) => (
            <MessagesIndexItem
                key={conversation.id || i}
                conversation={conversation}
                conversationIndex={i}
                startIndex={startIndex}
                appUrl={appUrl}
            />
        ))}
    </div>
);
MessagesIndexList.defaultProps = {
    startIndex: 0,
};

const MessagesIndexItem = ({
    conversation,
    conversationIndex,
    startIndex,
    appUrl,
}) => {
    const contact = conversation.contact || conversation;
    const lastThread = last(conversation.threads);
    const lastMessage = lastThread ? last(lastThread.messages) : null;

    // backwards compatibility until all conversations have an id
    const conversationId = conversation.id || startIndex + conversationIndex;

    return (
        <Link
            className={styles.messageIndexList__link}
            to={urljoin(appUrl, `conversation-${conversationId}`)}
        >
            <div className={styles.messageIndexList__item}>
                <div className={styles.messageIndexList__item__iconWrapper}>
                    <ContactPhoto contact={contact} size={40} />
                </div>
                <div className={styles.messageIndexList__item__details}>
                    <div className={styles.messageIndexList__item__title}>
                        <div className={styles.messageIndexList__item__name}>
                            <EmojiText text={contact.name || contact.number} />
                        </div>
                        {lastThread ? (
                            <div
                                className={styles.messageIndexList__item__date}
                            >
                                {formatDate(lastThread.date, DATE_FORMAT)}
                            </div>
                        ) : null}
                    </div>
                    <div className={styles.messageIndexList__item__lastMessage}>
                        {lastMessage ? (
                            <EmojiText
                                text={limitString(lastMessage.text, 40)}
                            />
                        ) : null}
                    </div>
                </div>
            </div>
        </Link>
    );
};

const ShowArchivedButton = ({ numConversations, onClick }) => (
    <div className={styles.showArchivedButton} onClick={onClick}>
        {`Recover ${numConversations} archived conversation${pluralize(
            numConversations
        )}`}
    </div>
);

const MessageIndexHeader = ({ appTitle }) => (
    <div className={styles.header}>
        <div>
            <div className={styles.header__title}>{appTitle || "Messages"}</div>
            <div className={styles.header__subtitle}>
                {"No unread messages"}
            </div>
        </div>
    </div>
);
